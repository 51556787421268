/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    margin-left: 0;
    float: left;
    left: -20px;
    position: relative;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    @include make-row();
}

.footer-col-1 {
   @include make-md-column(4);
}

.footer-col-2 {
    @include make-md-column(2);
}

.footer-col-3 {
    @include make-md-column(6);
}


/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: 2px;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
