/**
Slideshow CSS
**/
#slideshow { 
    margin: 50px auto;
    margin-top: 10px;
    position: absolute;
    right: 0;
    width: 240px; 
    height: 240px; 
    padding: 10px; 
    box-shadow: 0 0 20px rgba(0,0,0,0.4); 
    
    background-color: white;
    
    @media (max-width: $screen-sm-min) {
      display: none;
    }
}

#slideshow > div { 
    position: absolute; 
    top: 10px; 
    left: 10px; 
    right: 10px; 
    bottom: 10px; 
}


//Front page
#cta_div {
    min-height: 250px;
    margin-bottom: 50px;
}
