@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #FDF3E7;
$brand-color:      #7a26a2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}




//Navbar
nav {
    width: $content-width;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

div.wrapper, div.home {
    position: relative;
}

div.wrapper {
   // background-color: white;
}
ul.nav {
    position: absolute;
    bottom: 0;
    right: 0;
}

/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


// Core variables and mixins
@import "bootstrap/_mixins";
@import "bootstrap/_variables";

// Reset and dependencies
@import "bootstrap/_normalize";
@import "bootstrap/_print";
@import "bootstrap/_glyphicons";

// Core CSS
@import "bootstrap/_scaffolding";
@import "bootstrap/_type";
@import "bootstrap/_code";
@import "bootstrap/_grid";
@import "bootstrap/_tables";
@import "bootstrap/_forms";
@import "bootstrap/_buttons";

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "slideshow"
;



//Components
@import "bootstrap/media";
@import "bootstrap/navs";
/*


// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";

@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";

@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

*/